<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data User </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputUser">
            <CRow>
              <CCol sm="6">
              <CInput
                v-model="first_name"
                label="Nama Depan"
                placeholder="Input nama depan"
              />  
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="last_name"
                label="Nama Belakang"
                placeholder="Input nama belakang"
              />  
              </CCol>
              <!-- <CCol sm="6">
              <CInput
                v-model="username"
                label="Username"
                placeholder="Input username"
              />
              </CCol> -->
              <CCol sm="6">
              <CInput
                v-model="email"
                label="Email"
                placeholder="Input email"
              />
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="password"
                type="password"
                label="Password"
                placeholder="Input password"
              />  
              </CCol> 
              <CCol sm="6">
                <div class="form-group">
                <label> Level</label>
                  <select v-model="level" class="form-control">
                    <option value="" disabled selected>Pilih Level</option>
                    <option value="admin">Admin</option>
                    <option value="editor">Editor</option>
                    <option value="author">Author</option>
                  </select>
                </div>
              </CCol>      
            </CRow>          
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/user">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"      
    >
      <b>Error</b>! Data User gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import axios from 'axios';
// import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  data () {
    return {
      username : "",
      first_name : "",
      last_name : "",
      email : "",
      password : "",
      level : "",
      selected: [], // Must be an array reference!
      show: true,
      modalError: false,
    }
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    inputUser: function(){
      // Simple POST request with a JSON body using axios
      const user = { 
                          // username:this.username,
                          first_name: this.first_name, 
                          last_name: this.last_name, 
                          email: this.email, 
                          password: this.password,
                          level: this.level,
                          aktif: 1 
                          };
      // alert(JSON.stringify(user));
      axios.post(process.env.VUE_APP_BASE_URL+"user/insert", user)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/user');
            }
            else{
              this.modalError = true;
            }            
        })
    }
  }
}
</script>
